import React, { useState, useEffect, useRef, useCallback } from 'react';
import { createChart } from 'lightweight-charts';
import { useAccount, useBalance, useContractWrite } from 'wagmi';
import { parseEther, formatEther } from 'viem';
import Header from './Header';
import Footer from './Footer';
import { fetchPredictions } from '../api';
import SuccessAnimation from './SuccessAnimation';

const PredictionsPage = () => {
  const chartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [selectedEpoch, setSelectedEpoch] = useState(null);
  const [epochs, setEpochs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const [submittedEpochs, setSubmittedEpochs] = useState(new Set());

  const { address } = useAccount();
  const { data: balance } = useBalance({ address });

  // Contract interaction setup (replace with actual contract details)
  const contractAddress = 'YOUR_CONTRACT_ADDRESS';
  const contractABI = []; // Your contract ABI here
  const { write: submitEpochPrediction } = useContractWrite({
    address: contractAddress,
    abi: contractABI,
    functionName: 'submitPrediction',
  });

  const fetchPriceData = useCallback(async (asset) => {
    setLoading(true);
    setError(null);
    try {
      console.log('Fetching price data for asset:', asset);
      const data = await fetchPredictions(asset, '1'); // Default to 1 day timeframe
      console.log('Fetched price data:', data);
      return data.map(({ time, price }) => ({ time: time / 1000, value: parseFloat(price) }));
    } catch (err) {
      console.error('Error fetching price data:', err);
      setError(err.message);
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  const initChart = useCallback((asset) => {
    if (!chartContainerRef.current || !containerWidth) {
      console.log('Chart container not ready or no width');
      return;
    }

    if (chartRef.current) {
      chartRef.current.remove();
      chartRef.current = null;
    }

    console.log('Initializing chart for asset:', asset);
    const chart = createChart(chartContainerRef.current, {
      width: containerWidth,
      height: 400,
      layout: {
        background: { type: 'solid', color: '#ffffff' },
        textColor: '#333333',
      },
      grid: {
        vertLines: { color: '#f0f0f0' },
        horzLines: { color: '#f0f0f0' },
      },
    });

    chartRef.current = chart;

    fetchPriceData(asset).then(data => {
      if (data.length === 0) {
        console.log('No data available for chart');
        return;
      }
      console.log('Setting chart data:', data);
      const lineSeries = chart.addLineSeries({ color: '#2962FF' });
      lineSeries.setData(data);
      chart.timeScale().fitContent();
    });
  }, [containerWidth, fetchPriceData]);

  useEffect(() => {
    const updateContainerWidth = () => {
      if (chartContainerRef.current) {
        setContainerWidth(chartContainerRef.current.clientWidth);
      }
    };

    const resizeObserver = new ResizeObserver(entries => {
      if (!Array.isArray(entries) || !entries.length) return;
      updateContainerWidth();
    });

    const currentChartContainer = chartContainerRef.current;

    if (currentChartContainer) {
      resizeObserver.observe(currentChartContainer);
    }

    updateContainerWidth(); // Initial width set

    return () => {
      if (currentChartContainer) {
        resizeObserver.unobserve(currentChartContainer);
      }
    };
  }, []);

  useEffect(() => {
    if (containerWidth > 0 && selectedEpoch) {
      const asset = selectedEpoch.asset.split('/')[0].toLowerCase();
      console.log('Initializing chart for selected epoch:', asset);
      initChart(asset);
    }
  }, [initChart, containerWidth, selectedEpoch]);

  const fetchEpochs = useCallback(async () => {
    try {
      // Replace this with actual contract call when ready
      const mockEpochs = [
        { id: 1, asset: 'ETH/USD', closingTime: Date.now() + 3600000, poolSize: parseEther('10') },
        { id: 2, asset: 'BTC/USD', closingTime: Date.now() + 7200000, poolSize: parseEther('15') },
      ];
      setEpochs(mockEpochs);
    } catch (error) {
      console.error('Error fetching epochs:', error);
      setError('Failed to fetch epochs');
    }
  }, []);

  useEffect(() => {
    fetchEpochs();
  }, [fetchEpochs]);

  const handleEpochSelection = (epoch) => {
    console.log('Epoch selected:', epoch);
    setSelectedEpoch(epoch);
    const asset = epoch.asset.split('/')[0].toLowerCase();
    initChart(asset);
  };

  const handleSubmitPrediction = async (amount) => {
    if (!selectedEpoch || !amount) return;

    try {
      await submitEpochPrediction({
        args: [selectedEpoch.id, parseEther(amount)],
        value: parseEther(amount),
      });
      setShowSuccessAnimation(true);
      setSubmittedEpochs(new Set([...submittedEpochs, selectedEpoch.id]));
      setTimeout(() => setShowSuccessAnimation(false), 3000);
    } catch (err) {
      setError('Error submitting prediction: ' + err.message);
    }
  };

  const formatTimeLeft = (closingTime) => {
    const timeLeft = closingTime - Date.now();
    const hours = Math.floor(timeLeft / 3600000);
    const minutes = Math.floor((timeLeft % 3600000) / 60000);
    return `${hours}h ${minutes}m`;
  };

  if (loading) {
    return <div className="loader">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Header />
      <main className="flex-grow container mx-auto p-4">
        <h1 className="text-4xl font-bold mb-6 text-center">Predictions</h1>

        <div className="mb-6 p-4 bg-white border border-gray-200 rounded-lg shadow-md">
          <div ref={chartContainerRef} className="w-full h-96"></div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h2 className="text-2xl font-bold mb-4">Prediction Epochs</h2>
            <div className="grid grid-cols-1 gap-4 mb-4">
              {epochs.map((epoch) => (
                <div 
                  key={epoch.id} 
                  className={`p-4 border rounded-lg cursor-pointer ${
                    selectedEpoch?.id === epoch.id ? 'bg-blue-100 border-blue-500' : 'bg-white'
                  } ${submittedEpochs.has(epoch.id) ? 'opacity-50' : ''}`}
                  onClick={() => handleEpochSelection(epoch)}
                >
                  <h3 className="text-lg font-semibold">{epoch.asset}</h3>
                  <p>Closes in: {formatTimeLeft(epoch.closingTime)}</p>
                  <p>Pool Size: {formatEther(epoch.poolSize)} ETH</p>
                  {submittedEpochs.has(epoch.id) && (
                    <p className="text-green-500 font-bold">Prediction Submitted</p>
                  )}
                </div>
              ))}
            </div>
          </div>

          {selectedEpoch && !submittedEpochs.has(selectedEpoch.id) && (
            <div className="bg-gray-100 p-4 rounded-lg">
              <h3 className="text-xl font-semibold mb-2">Submit Prediction</h3>
              <p>Selected Epoch: {selectedEpoch.asset}</p>
              <p>Your Balance: {balance ? formatEther(balance.value) : '0'} ETH</p>
              <input
                type="number"
                placeholder="ETH Amount"
                className="w-full p-2 mb-2 border rounded"
                onChange={(e) => handleSubmitPrediction(e.target.value)}
              />
              <button 
                onClick={() => handleSubmitPrediction(document.querySelector('input[type="number"]').value)}
                className="w-full bg-green-500 text-white p-2 rounded hover:bg-green-600"
              >
                Submit
              </button>
            </div>
          )}
        </div>

        {showSuccessAnimation && <SuccessAnimation />}
      </main>
      <Footer />
    </div>
  );
};

export default PredictionsPage;