import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { fetchLeaderboard } from '../api';

const LeaderboardModule = ({ title, data, onViewAll }) => (
  <div className="bg-white rounded-lg shadow-md p-6 mb-6">
    <h2 className="text-2xl font-bold mb-4">{title}</h2>
    <table className="w-full">
      <thead>
        <tr className="bg-gray-100">
          <th className="p-2 text-left">Rank</th>
          <th className="p-2 text-left">Username</th>
          <th className="p-2 text-left">Score</th>
        </tr>
      </thead>
      <tbody>
        {data.map((user, index) => (
          <tr key={user.id} className="border-b">
            <td className="p-2">{index + 1}</td>
            <td className="p-2">
              <Link to={`/user/${user.id}`} className="text-blue-500 hover:underline">
                {user.username}
              </Link>
            </td>
            <td className="p-2">{user.score}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <button 
      onClick={onViewAll}
      className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
    >
      View All
    </button>
  </div>
);

const LeaderboardPage = () => {
  const [leaderboards, setLeaderboards] = useState({
    daily: [],
    weekly: [],
    monthly: [],
    global: []
  });
  const [selectedLeaderboard, setSelectedLeaderboard] = useState(null);
  const [showAllData, setShowAllData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllLeaderboards = async () => {
      setLoading(true);
      try {
        const [daily, weekly, monthly, global] = await Promise.all([
          fetchLeaderboard('daily'),
          fetchLeaderboard('weekly'),
          fetchLeaderboard('monthly'),
          fetchLeaderboard('global')
        ]);
        setLeaderboards({ daily, weekly, monthly, global });
      } catch (err) {
        console.error('Error fetching leaderboards:', err);
        setError(`Failed to fetch leaderboards: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };
  
    fetchAllLeaderboards();
  }, []);

  const handleViewAll = (leaderboardType) => {
    setSelectedLeaderboard(leaderboardType);
    setShowAllData(true);
  };

  const handleBack = () => {
    setSelectedLeaderboard(null);
    setShowAllData(false);
  };

  if (loading) {
    return (
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow flex items-center justify-center">
          <div className="loader">Loading...</div>
        </main>
        <Footer />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow flex items-center justify-center">
          <div className="error">Error: {error}</div>
        </main>
        <Footer />
      </div>
    );
  }

  if (showAllData && selectedLeaderboard) {
    const data = leaderboards[selectedLeaderboard];
    return (
      <div className="flex flex-col min-h-screen bg-gray-100">
        <Header />
        <main className="flex-grow container mx-auto p-4">
          <h1 className="text-4xl font-bold mb-6 text-center">{selectedLeaderboard.charAt(0).toUpperCase() + selectedLeaderboard.slice(1)} Leaderboard</h1>
          <button 
            onClick={handleBack}
            className="mb-4 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
          >
            Back to Summary
          </button>
          <div className="bg-white rounded-lg shadow-md p-6">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-2 text-left">Rank</th>
                  <th className="p-2 text-left">Username</th>
                  <th className="p-2 text-left">Score</th>
                </tr>
              </thead>
              <tbody>
                {data.map((user, index) => (
                  <tr key={user.id} className="border-b">
                    <td className="p-2">{index + 1}</td>
                    <td className="p-2">
                      <Link to={`/user/${user.id}`} className="text-blue-500 hover:underline">
                        {user.username}
                      </Link>
                    </td>
                    <td className="p-2">{user.score}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header />
      <main className="flex-grow container mx-auto p-4">
        <h1 className="text-4xl font-bold mb-6 text-center">Leaderboards</h1>

        <LeaderboardModule 
          title="Daily Leaderboard" 
          data={leaderboards.daily.slice(0, 10)}
          onViewAll={() => handleViewAll('daily')}
        />

        <LeaderboardModule 
          title="Weekly Leaderboard" 
          data={leaderboards.weekly.slice(0, 10)}
          onViewAll={() => handleViewAll('weekly')}
        />

        <LeaderboardModule 
          title="Monthly Leaderboard" 
          data={leaderboards.monthly.slice(0, 10)}
          onViewAll={() => handleViewAll('monthly')}
        />

        <LeaderboardModule 
          title="Global Leaderboard" 
          data={leaderboards.global.slice(0, 10)}
          onViewAll={() => handleViewAll('global')}
        />
      </main>
      <Footer />
    </div>
  );
};

export default LeaderboardPage;