import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useEnsName } from 'wagmi';
import Header from './Header';
import Footer from './Footer';
import { fetchUserData } from '../api';

const PointsModule = ({ points }) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
    const timer = setTimeout(() => setAnimate(false), 1000);
    return () => clearTimeout(timer);
  }, [points]);

  return (
    <div className="bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 p-6 rounded-lg shadow-lg text-white">
      <h2 className="text-3xl font-bold mb-4">Prediction Points</h2>
      <div className="relative">
        <div className={`text-6xl font-bold ${animate ? 'animate-bounce' : ''}`}>
          {points.toLocaleString()}
        </div>
        <div className="absolute top-0 right-0 text-yellow-300 text-4xl">
          ✨
        </div>
      </div>
      <p className="mt-4 text-lg">Keep predicting to earn more!</p>
      <div className="mt-6 bg-white bg-opacity-20 rounded-full h-4">
        <div 
          className="bg-yellow-300 h-full rounded-full transition-all duration-1000 ease-in-out"
          style={{ width: `${(points % 1000) / 10}%` }}
        ></div>
      </div>
      <p className="mt-2 text-sm">
        {1000 - (points % 1000)} points to next level
      </p>
    </div>
  );
};

const UserPage = () => {
  const { address } = useParams();
  const { data: ensName } = useEnsName({ address });
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        console.log('Fetching data for address:', address);
        const data = await fetchUserData(address);
        setUserData(data);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError(`Failed to fetch user data: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (address) {
      fetchData();
    }
  }, [address]);

  if (loading) {
    return (
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow flex items-center justify-center">
          <div className="loader">Loading...</div>
        </main>
        <Footer />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow flex items-center justify-center">
          <div className="error">Error: {error}</div>
        </main>
        <Footer />
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow flex items-center justify-center">
          <div>No user data found for address: {address}</div>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header />
      <main className="flex-grow container mx-auto p-4">
        <h1 className="text-4xl font-bold mb-6 text-center">{userData.ens_name || userData.wallet_address}'s Profile</h1>

        <div className="flex flex-col md:flex-row gap-6">
          <div className="w-full md:w-1/2 bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-bold mb-4">User Information</h2>
            <p><strong>Ethereum Address:</strong> {userData.wallet_address}</p>
            {ensName && <p><strong>ENS Name:</strong> {ensName}</p>}
            <p><strong>Points:</strong> {userData.points}</p>

            {/* If you have predictions data, you can uncomment and adjust this section */}
            {/*
            <h3 className="text-xl font-bold mt-6 mb-2">Recent Predictions</h3>
            <table className="w-full">
              <thead>
                <tr className="bg-gray-200">
                  <th className="p-2 text-left">Asset</th>
                  <th className="p-2 text-left">Predicted</th>
                  <th className="p-2 text-left">Actual</th>
                  <th className="p-2 text-left">Result</th>
                </tr>
              </thead>
              <tbody>
                {userData.predictions && userData.predictions.map((prediction, index) => (
                  <tr key={index} className="border-b">
                    <td className="p-2">{prediction.asset}</td>
                    <td className="p-2">{prediction.predicted_price}</td>
                    <td className="p-2">{prediction.actual_price}</td>
                    <td className="p-2">{prediction.result}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            */}
          </div>

          <div className="w-full md:w-1/2">
            <PointsModule points={userData.points} />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default UserPage;