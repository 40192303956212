// src/api.js

const API_BASE_URL = 'http://localhost:3001/api'; // Adjust this if your backend is on a different port

export const fetchLeaderboard = async (type = 'global') => {
  const response = await fetch(`${API_BASE_URL}/leaderboard?type=${type}`);
  if (!response.ok) throw new Error('Failed to fetch leaderboard');
  return response.json();
};

export const fetchPredictions = async (asset, timeframe) => {
  console.log(`Fetching predictions from: ${API_BASE_URL}/predictions?asset=${asset}&timeframe=${timeframe}`);
  const response = await fetch(`${API_BASE_URL}/predictions?asset=${asset}&timeframe=${timeframe}`);
  if (!response.ok) {
    const errorText = await response.text();
    console.error('Error response:', errorText);
    throw new Error(`Failed to fetch predictions: ${response.status} ${response.statusText}`);
  }
  const data = await response.json();
  console.log('Fetched predictions:', data);
  return data;
};

export const submitPrediction = async (data) => {
  const response = await fetch(`${API_BASE_URL}/predictions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) throw new Error('Failed to submit prediction');
  return response.json();
};

export const fetchUserData = async (address) => {
  try {
    const url = `${API_BASE_URL}/users/${address}`;
    console.log('Fetching user data from:', url);
    const response = await fetch(url);
    if (!response.ok) {
      const text = await response.text();
      console.error('Error response:', text);
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    console.log('Received user data:', data);
    return data;
  } catch (error) {
    console.error('Error in fetchUserData:', error);
    throw error;
  }
};

export const createOrUpdateUser = async (walletAddress, ensName) => {
  try {
    const response = await fetch(`${API_BASE_URL}/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ walletAddress, ensName }),
    });
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to create/update user: ${response.status} ${errorText}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error in createOrUpdateUser:', error);
    throw error;
  }
};