import React from 'react';
import { Link } from 'react-router-dom';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useEnsName } from 'wagmi';
import { shortenAddress } from '../utils'; // Ensure this utility function exists

const Header = () => {
  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });

  return (
    <header className="bg-white shadow-md">
      <div className="container mx-auto px-4 py-3">
        {/* First row: Logo and RainbowKit */}
        <div className="flex flex-col sm:flex-row justify-between items-center mb-3">
          <Link to="/" className="text-3xl font-bold text-blue-600 mb-2 sm:mb-0">PUDDING</Link>
          <ConnectButton />
        </div>
        
        {/* Second row: Navigation menu */}
        <nav className="flex flex-col sm:flex-row justify-between items-center">
          <div className="space-x-4 mb-2 sm:mb-0">
            <Link to="/" className="text-gray-600 hover:text-blue-600">Home</Link>
            <Link to="/predictions" className="text-gray-600 hover:text-blue-600">Predictions</Link>
            <Link to="/leaderboard" className="text-gray-600 hover:text-blue-600">Leaderboard</Link>
          </div>
          {isConnected && address && (
            <Link 
              to={`/user/${address}`} 
              className="text-blue-600 hover:text-blue-800"
            >
              {ensName || shortenAddress(address)}
            </Link>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;