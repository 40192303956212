import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-matrix-dark text-matrix-green border-t-2 border-matrix-green py-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-start">
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <h3 className="text-xl font-bold mb-2 glitch" data-text="PUDDING">PUDDING</h3>
            <p className="text-sm">A Decentralized Platform for Social Trading and Time Series Prediction in Cryptocurrency Markets</p>
          </div>
          <div className="w-full md:w-1/3 mb-4 md:mb-0">
            <h4 className="text-lg font-semibold mb-2">Quick Links</h4>
            <ul className="space-y-2">
              <li><Link to="/privacy" className="hover:text-accent-color transition-colors">Privacy Policy</Link></li>
              <li><Link to="/terms" className="hover:text-accent-color transition-colors">User Agreement</Link></li>
              <li><Link to="/whitepaper" className="hover:text-accent-color transition-colors">Whitepaper</Link></li>
            </ul>
          </div>
          <div className="w-full md:w-1/3">
            <h4 className="text-lg font-semibold mb-2">Connect</h4>
            <div className="flex space-x-4">
              <a href="https://twitter.com/pudding" target="_blank" rel="noopener noreferrer" className="hover:text-accent-color transition-colors">
                Twitter
              </a>
              <a href="https://discord.gg/pudding" target="_blank" rel="noopener noreferrer" className="hover:text-accent-color transition-colors">
                Discord
              </a>
              <a href="https://t.me/pudding" target="_blank" rel="noopener noreferrer" className="hover:text-accent-color transition-colors">
                Telegram
              </a>
            </div>
          </div>
        </div>
        <div className="mt-6 text-center text-sm">
          <p>&copy; {new Date().getFullYear()} PUDDING. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;