import React, { useState, useEffect } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useEnsName } from 'wagmi';
import { useNavigate } from 'react-router-dom';
import Header from './Header'; // Import the Header component
import Footer from './Footer'; // Import the Footer component
import { fetchUserData, createOrUpdateUser } from '../api';

const HomePage = () => {
  const { isConnected, address } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isConnected && address) {
      setLoading(true);
      fetchUserData(address)
        .then(userData => {
          setUser(userData);
          setLoading(false);
        })
        .catch(error => {
          if (error.message.includes('404')) {
            createOrUpdateUser(address, ensName)
              .then(newUser => {
                setUser(newUser);
                setLoading(false);
              })
              .catch(createError => {
                console.error('Error creating user:', createError);
                setLoading(false);
              });
          } else {
            console.error('Error fetching user data:', error);
            setLoading(false);
          }
        });
    }
  }, [isConnected, address, ensName]);

  const handleLaunchApp = () => {
    if (user) {
      navigate('/predictions');
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow flex items-center justify-center bg-cover bg-center" style={{backgroundImage: "url('/path/to/your/background-image.jpg')"}}>
        <div className="text-center bg-white bg-opacity-80 p-8 rounded-lg shadow-lg">
          <h1 className="text-4xl font-bold mb-4 text-blue-600">Welcome to PUDDING</h1>
          <p className="text-xl mb-6">Your platform for cryptocurrency price predictions</p>
          {!isConnected ? (
            <div className="mb-4">
              <ConnectButton />
            </div>
          ) : loading ? (
            <p>Loading user data...</p>
          ) : user ? (
            <button
              onClick={handleLaunchApp}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              LAUNCH APP
            </button>
          ) : (
            <p>Error loading user data. Please try again.</p>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;