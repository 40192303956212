import React from 'react';
import { motion } from 'framer-motion';

const SuccessAnimation = () => {
  const emojis = ['💰', '⭐', '🎉', '🚀'];

  return (
    <div className="fixed inset-0 pointer-events-none z-50">
      {emojis.map((emoji, index) => (
        <motion.div
          key={index}
          initial={{ y: -50, opacity: 0 }}
          animate={{
            y: [null, window.innerHeight],
            opacity: [0, 1, 0],
          }}
          transition={{
            duration: 2,
            ease: "easeOut",
            times: [0, 0.8, 1],
            delay: Math.random() * 0.5,
            repeat: Infinity,
            repeatDelay: 1
          }}
          style={{
            position: 'absolute',
            left: `${Math.random() * 100}%`,
            fontSize: '2rem',
          }}
        >
          {emoji}
        </motion.div>
      ))}
    </div>
  );
};

export default SuccessAnimation;